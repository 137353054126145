<template lang="pug">
.team
  season-segment-selector(
    :leagueId="leagueId",
    @leaf-node-clicked="leafNodeClicked"
  )
  team-leader-table.bar_container(
    :teamLeaders="teamLeaders",
    textCenter,
    :divider="false",
    @teamClick="teamClick"
  )
  v-container.bar_container
    v-row(style="margin-top: 100px")
      v-col.title(cols="12")
        h5.normal_title_h5 進攻排名
        span Offensive
    v-row.my-2
      v-col.position-relative(style="padding-bottom: 0", cols="12")
        v-divider.position-absolute.bottom-0.w-100.left-0
        .tabs
          v-tabs.sheet-s-border.position-relative(
            v-model="offensiveTab",
            ripple="false"
          )
            v-tabs-slider(hidden="true")
            v-tab(
              v-for="(ranking, index) in offensiveTitleList",
              :key="ranking.type",
              :ripple="false"
            ) {{ ranking.title }}
        span.d-none.d-sm-block.font-italic.font-weight-bold.position-absolute Offensive
    v-row.py-1.px-1.px-md-10-lg-8(
      v-for="(item, index) in currentOffensiveList",
      align="center",
      :key="index"
    )
      v-col.d-flex.justify-end.align-center(cols="5", sm="4", md="3", lg="2")
        .ranking.text-center.ranking_mark(
          :class="[index + 1 <= 3 ? 'text_secondary' : 'lightGray']"
        ) {{ getRankText(index + 1) }}
        .text-body-2.font-weight-bold.clickable.d-inline-block.ml-2(
          @click="teamClick(item)"
        ) {{ item.name }}
      v-col.d-flex.align-center.flex-grow-1(
        cols="auto",
        sm="8",
        md="9",
        lg="10"
      )
        percentage-bar(
          :height="14",
          :value="item.value",
          :deno="item.max_value",
          :percent="item.show_percent_symbol"
        )
  v-container.bar_container
    v-row(style="margin-top: 100px")
      v-col.title(cols="12")
        h5.normal_title_h5 防守排名
        span Defensive
    v-row.my-2
      v-col.position-relative(style="padding-bottom: 0", cols="12")
        v-divider.position-absolute.bottom-0.w-100.left-0
        .tabs
          v-tabs.sheet-s-border.position-relative(
            v-model="defensiveTab",
            ripple="false"
          )
            v-tabs-slider(hidden="true")
            v-tab(
              v-for="(ranking, index) in defensiveTitleList",
              :key="ranking.type",
              :ripple="false"
            ) {{ ranking.title }}
        span.d-none.d-sm-block.font-italic.font-weight-bold.position-absolute Defensive

    v-row.py-1.px-1.px-md-12-lg-8(
      v-for="(item, index) in currentDefensiveList",
      align="center",
      :key="index"
    )
      v-col.d-flex.justify-end.align-center(cols="5", sm="4", md="3", lg="2")
        .ranking.text-center.ranking_mark(
          :class="[index + 1 <= 3 ? 'text_secondary' : 'lightGray']"
        ) {{ getRankText(index + 1) }}
        .text-body-2.font-weight-bold.clickable.d-inline-block.ml-2(
          @click="teamClick(item)"
        ) {{ item.name }}
      v-col.d-flex.align-center.flex-grow-1(
        cols="auto",
        sm="8",
        md="9",
        lg="10"
      )
        percentage-bar(
          :height="14",
          :value="item.value",
          :deno="item.max_value",
          :percent="item.show_percent_symbol"
        )
  v-container.bar_container
    v-row(style="margin-top: 100px")
      v-col.title(cols="12")
        h5.normal_title_h5 投籃排名
        span Field Goal
    v-row.my-2
      v-col.position-relative(style="padding-bottom: 0", cols="12")
        v-divider.position-absolute.bottom-0.w-100.left-0
        .tabs
          v-tabs.sheet-s-border.position-relative(
            v-model="fieldGoalTab",
            ripple="false"
          )
            v-tabs-slider(hidden="true")
            v-tab(
              v-for="(ranking, index) in fieldGoalTitleList",
              :key="ranking.type",
              :ripple="false"
            ) {{ ranking.title }}
        span.d-none.d-sm-block.d-none.d-sm-block.font-italic.font-weight-bold.position-absolute Field Goal
    v-row.py-1.px-1.px-md-10-lg-8(
      v-for="(item, index) in currentFieldGoalList",
      align="center",
      :key="index"
    )
      v-col.d-flex.justify-end.align-center(cols="5", sm="4", md="3", lg="2")
        .ranking.text-center.ranking_mark(
          :class="[index + 1 <= 3 ? 'text_secondary' : 'lightGray']"
        ) {{ getRankText(index + 1) }}
        .text-body-2.font-weight-bold.clickable.d-inline-block.ml-2(
          @click="teamClick(item)"
        ) {{ item.name }}
      v-col.d-flex.align-center.flex-grow-1(
        cols="auto",
        sm="8",
        md="9",
        lg="10"
      )
        percentage-bar(
          :height="14",
          :value="item.value",
          :deno="item.max_value",
          :percent="item.show_percent_symbol"
        )
  v-container.bar_container
    v-row(style="margin-top: 100px")
      v-col.title(cols="12")
        h5.normal_title_h5 罰球排名
        span Free Throw
    v-row.my-2
      v-col.position-relative(style="padding-bottom: 0", cols="12")
        v-divider.position-absolute.bottom-0.w-100.left-0
        .tabs
          v-tabs.sheet-s-border.position-relative(
            v-model="freeThrowTab",
            ripple="false"
          )
            v-tabs-slider(hidden="true")
            v-tab(
              v-for="(ranking, index) in freeThrowTitleList",
              :key="ranking.type",
              :ripple="false"
            ) {{ ranking.title }}
        span.d-none.d-sm-block.font-italic.font-weight-bold.position-absolute Free Throw
    v-row.py-1.px-1.px-md-10-lg-8(
      v-for="(item, index) in currentFreeThrowList",
      align="center",
      :key="index",
      style="padding: 5px 40px"
    )
      v-col.d-flex.justify-end.align-center(cols="5", sm="4", md="3", lg="2")
        .ranking.text-center.ranking_mark(
          :class="[index + 1 <= 3 ? 'text_secondary' : 'lightGray']"
        ) {{ getRankText(index + 1) }}
        .text-body-2.font-weight-bold.clickable.d-inline-block.ml-2(
          @click="teamClick(item)"
        ) {{ item.name }}
      v-col.d-flex.align-center.flex-grow-1(
        cols="auto",
        sm="8",
        md="9",
        lg="10"
      )
        percentage-bar(
          :height="14",
          :value="item.value",
          :deno="item.max_value",
          :percent="item.show_percent_symbol"
        )
</template>

<script>
import RankingMark from "@/components/common/RankingMark";
import PercentageBar from "@/components/common/PercentageBar";
import StickyColumn from "@/components/common/Table/StickyColumn";
import SeasonSegmentSelector from "@/components/league/SeasonSegmentSelector";
import TeamLeaderTable from "@/components/league/TeamLeaderTable";
import { getTeamOverview } from "@/api/league";

export default {
  name: "LeagueTeamOverview",
  metaInfo() {
    return {
      title: this.metaData.title,
      meta: [
        {
          name: "description",
          content: this.metaData.description,
        },
        {
          name: "keywords",
          content: this.metaData.keywords,
        },
        { property: "og:site_name", content: this.metaData.siteTitle },
        {
          property: "og:title",
          content: this.metaData.title,
        },
        {
          property: "og:description",
          content: this.metaData.description,
        },
        { name: "og:url", content: this.metaData.url },
        { name: "og:image", content: this.metaData.image },
        { name: "smo_type", content: this.metaData.smoType },
      ],
    };
  },
  components: {
    RankingMark,
    PercentageBar,
    StickyColumn,
    SeasonSegmentSelector,
    TeamLeaderTable,
  },
  data() {
    return {
      leagueId: Number(this.$route.params.leagueId),
      seasonId: 0,
      seasonSegmentId: 0,
      teamLeaders: [],
      offensiveLeaderData: [],
      defensiveLeaderData: [],
      fieldGoalLeaderData: [],
      freeThrowLeaderData: [],

      offensiveTab: 0,
      defensiveTab: 0,
      fieldGoalTab: 0,
      freeThrowTab: 0,

      offensiveTitleList: [
        {
          type: "score",
          title: "得分",
        },
        {
          type: "assist",
          title: "助攻",
        },
        {
          type: "turnover",
          title: "失誤",
        },
      ],
      defensiveTitleList: [
        {
          type: "rebound",
          title: "籃板",
        },
        {
          type: "block",
          title: "阻攻",
        },
        {
          type: "steal",
          title: "抄截",
        },
      ],
      fieldGoalTitleList: [
        {
          type: "fieldGoalPer",
          title: "投籃命中率",
        },
        {
          type: "threePointShotPer",
          title: "三分命中率",
        },
        {
          type: "threePointShot",
          title: "三分命中",
        },
      ],
      freeThrowTitleList: [
        {
          type: "freeThrowPer",
          title: "罰球命中率",
        },
        {
          type: "freeThrow",
          title: "罰球命中",
        },
      ],

      metaData: {
        siteTitle: "籃球數據分析Game Changer",
        title: "籃球數據分析Game Changer",
        description:
          "提供台灣籃球完整資訊，包含中文字幕影音、精彩Highlights、文字轉播、最新消息、精彩圖輯、球隊與球員詳細數據、賽程比分、名家專欄等內容。",
        keywords: "Game changer,籃球數據分析,戰術分享,球員履歷",
        url: "https://www.gamechanger.tw/",
        image: "",
        smoType: "website",
      },
    };
  },
  computed: {
    currentOffensiveList() {
      return this.offensiveLeaderData[this.offensiveTab];
    },
    currentDefensiveList() {
      return this.defensiveLeaderData[this.defensiveTab];
    },
    currentFieldGoalList() {
      return this.fieldGoalLeaderData[this.fieldGoalTab];
    },
    currentFreeThrowList() {
      return this.freeThrowLeaderData[this.freeThrowTab];
    },
  },
  watch: {
    seasonSegmentId() {
      this.offensiveTab = 0;
      this.defensiveTab = 0;
      this.fieldGoalTab = 0;
      this.freeThrowTab = 0;
      this.getTeamOverviewApi();
    },
  },
  created() {
    this.initData();
  },
  methods: {
    async initData() {},
    leafNodeClicked(node) {
      this.seasonSegmentId = node.id;
      this.seasonId = node.season_id;
    },
    async getTeamOverviewApi() {
      const params = { season_segment_id: this.seasonSegmentId };
      const response = await getTeamOverview(params);
      this.teamLeaders = response.data.team_leaders;
      this.offensiveLeaderData = response.data.leader_data.offensive;
      this.defensiveLeaderData = response.data.leader_data.defensive;
      this.fieldGoalLeaderData = response.data.leader_data.field_goal;
      this.freeThrowLeaderData = response.data.leader_data.free_throw;
    },
    getRankText(rank) {
      if (rank == 1) {
        return "1st";
      } else if (rank == 2) {
        return "2nd";
      } else if (rank == 3) {
        return "3rd";
      } else {
        return rank + "th";
      }
    },
    getStreakText(count) {
      if (count > 1) {
        return count + "連勝";
      } else if (count < -1) {
        return 0 - count + "連敗";
      } else {
        return "-";
      }
    },
    teamClick(team) {
      this.$router.push({
        name: "LeagueTeam",
        params: {
          leagueId: this.leagueId,
          seasonSegmentId: this.seasonSegmentId,
          teamId: team.team_id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/common/table";
.bar_container {
  .title {
    text-align: center;
    h5 {
      font-weight: bold;
      display: block;
    }

    span {
      display: none;
    }
  }
}

svg {
  color: $secondary;
}

::v-deep.theme--light.v-tabs > .v-tabs-bar {
  background: transparent;
}

span.d-none.d-sm-block.font-italic.font-weight-bold.position-absolute {
  top: 12px;
  right: 12px;
  font-family: Helvetica;
  font-size: 20px;
}

.ranking {
  font-weight: bold;
}

@media (max-width: 599px) {
  .bar_container {
    .title {
      text-align: left;
      h5 {
        display: inline-block;
        line-height: 30px;
      }
      span {
        font-weight: bold;
        font-style: italic;
        display: inline-block;
        margin-left: 5px;
        line-height: 30px;
      }
    }
  }
}
</style>
